import React from "react";
import Bade1 from "../media/zipBdg01footer.svg";
import Bade2 from "../media/zipBdg02footer.svg";
import Bade3 from "../media/zipBdg03footer.svg";
const Footer = () => {
  const images = [Bade1, Bade2, Bade3];
  return (
    <div className=" bg-green bg-contain md:bg-center lg:bg-top lg:bg-cover bg-no-repeat footer flex flex-wrap p-5">
      <div
        className=" lg:px-12 py-4 md:py-12 flex flex-col justify-between w-full md:w-1/2"
        style={{ height: "100%" }}
      >
        <div>
          <div className="flex">
            <a
              rel="noreferrer noopener"
              target="_blank"
              href="https://www.facebook.com/ZipLandscapes"
            >
              <img
                className="mr-12"
                style={{ height: "25px", width: "25px" }}
                src={require("../assets/facebook.svg")}
                alt="facebook-icon"
              ></img>
            </a>
            <a
              rel="noreferrer noopener"
              target="_blank"
              href="https://www.instagram.com/ziplandscapes/"
            >
              <img
                className="mr-12"
                style={{ height: "25px", width: "25px" }}
                src={require("../assets/instagram.svg")}
                alt="instagram-icon"
              ></img>
            </a>
            <a
              rel="noreferrer noopener"
              target="_blank"
              href="https://www.yelp.com/biz/zip-landscapes-myrtle-beach"
            >
              {" "}
              <img
                className="mr-12"
                style={{ height: "25px", width: "25px" }}
                src={require("../assets/yelp.svg")}
                alt="yelp-icon"
              ></img>
            </a>
          </div>
        </div>
        <div className="mt-12">
          <h4 className="text-white font-medium">
            Website by{" "}
            <a
              href="https://elementsoftworks.co.uk/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Element Softworks Ltd
            </a>
          </h4>
          <h4 className="text-white font-medium">Zip Landscapes 2020©</h4>
        </div>
      </div>
      <div className="w-full md:w-1/2 text-lg flex footer-images items-center flex-wrap justify-center">
        {images?.map((item) => {
          return <img src={item} className="mx-2" />;
        })}
      </div>
    </div>
  );
};

export default Footer;
