import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";

function SEO() {
  return (
    <Helmet>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={require("../assets/favicon/apple-touch-icon.png")}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={require("../assets/favicon/favicon-32x32.png")}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={require("../assets/favicon/favicon-16x16.png")}
      />

      <link
        rel="mask-icon"
        href={require("../assets/favicon/safari-pinned-tab.svg")}
        color="#101014"
      />
      <link
        rel="shortcut icon"
        href={require("../assets/favicon/favicon.ico")}
      />
      <link rel="manifest" href="/favicon/site.webmanifest" />
      <link
        rel="mask-icon"
        href="/favicon/safari-pinned-tab.svg"
        color="#5bbad5"
      />
      <meta name="msapplication-TileColor" content="#00aba9" />
      <meta name="msapplication-config" content="/favicon/browserconfig.xml" />
      <meta name="theme-color" content="#ffffff" />
      {/* Primary Meta Tags */}
      <title>SERVING THE MYRTLE BEACH COMMUNITY SINCE 2011</title>
      <meta
        name="title"
        content="SERVING THE MYRTLE BEACH COMMUNITY SINCE 2011"
      />
      <meta
        name="description"
        content="We are a premiere landscaping business serving the Grand Strand at affordable prices. Providing landscaping services to local commercial and residential properties."
      />
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://zip-landscapes.netlify.app" />
      <meta
        property="og:title"
        content="SERVING THE MYRTLE BEACH COMMUNITY SINCE 2011"
      />
      <meta
        property="og:description"
        content="We are a premiere landscaping business serving the Grand Strand at affordable prices. Providing landscaping services to local commercial and residential properties."
      />
      <meta
        property="og:image"
        content="https://zip-landscapes.netlify.app/preview.jpg"
      />
      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:url"
        content="https://zip-landscapes.netlify.app"
      />
      <meta
        property="twitter:title"
        content="SERVING THE MYRTLE BEACH COMMUNITY SINCE 2011"
      />
      <meta
        property="twitter:description"
        content="We are a premiere landscaping business serving the Grand Strand at affordable prices. Providing landscaping services to local commercial and residential properties."
      />
      <meta
        property="twitter:image"
        content="https://zip-landscapes.netlify.app/preview.jpg"
      />
      <html lang="en" />
      <link rel="canonical" href="https://zip-landscapes.netlify.app" />
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  keywords: [],
  meta: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  lang: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string.isRequired,
};

export default SEO;
