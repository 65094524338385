import { graphql, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import { Link, animateScroll as scroll } from "react-scroll";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  const openNav = () => {
    toggleExpansion(true);
  };
  const closenav = () => {
    toggleExpansion(false);
  };
  return (
    <header className="max-w-full">
      <div className="flex items-center justify-between  py-2 px-2  md:px-10">
        <Link className="flex items-center no-underline text-black logo" to="/">
          <img
            style={{ height: "100%" }}
            src={require("../assets/zl-solid.svg")}
            alt="zl-logo"
          ></img>
        </Link>

        <span
          className="text-3xl cursor:pointer font-bold text-3xl md:text-4xl robotoFont block md:hidden"
          onClick={() => {
            openNav();
          }}
        >
          &#9776;
        </span>

        <nav
          className={`hidden md:block md:flex md:items-center w-full md:w-auto`}
        >
          {[
            {
              route: `/`,
              title: `HOME`,
            },
            {
              route: `about`,
              title: `ABOUT`,
            },
            {
              route: `gallery`,
              title: `GALLERY`,
            },
            {
              route: `team`,
              title: `TEAM`,
            },
            {
              route: `contact`,
              title: `CONTACT`,
            },
          ].map((link) => (
            <Link
              className="tracking-wider block md:inline-block mt-4 md:mt-0 md:ml-10 lg:mt-0 lg:ml-32 no-underline text-black text-md cursor-pointer font-bold"
              key={link.title}
              to={link.route}
              spy={true}
              smooth={true}
              offset={-120}
              duration={900}
              hashSpy={true}
            >
              {link.title}
            </Link>
          ))}
        </nav>
      </div>
      {/* Mobile nav */}
      <div
        id="mySidenav"
        className={`${isExpanded ? " w-full px-8" : "w-0"} sidenav py-8 `}
      >
        <div className=" flex justify-between">
          <Link
            className="flex items-center no-underline text-black logo"
            to="/"
          >
            <img
              style={{ height: "100%" }}
              src={require("../assets/zl-solid.svg")}
              alt="zl-logo"
            ></img>
          </Link>

          <span
            className="text-3xl text-black cursor:pointer"
            onClick={() => {
              closenav();
            }}
          >
            &#9776;
          </span>
        </div>
        <div className="navigations py-5">
          {[
            {
              route: ``,
              title: `HOME`,
            },
            {
              route: `about`,
              title: `ABOUT`,
            },
            {
              route: `gallery`,
              title: `GALLERY`,
            },
            {
              route: `team`,
              title: `TEAM`,
            },
            {
              route: `contact`,
              title: `CONTACT`,
            },
          ].map((link) => (
            <Link
              className="no-underline text-md cursor-pointer text-2xl  py-6 block"
              key={link.title}
              to={link.route}
              spy={true}
              smooth={true}
              offset={-120}
              duration={900}
              hashSpy={true}
              onClick={() => {
                closenav();
              }}
            >
              {link.title}
            </Link>
          ))}
        </div>
      </div>
    </header>
  );
}

export default Header;
