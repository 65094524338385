import PropTypes from "prop-types";
import React from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import Header from "./header";
import Footer from "./Footer";

import { library } from "@fortawesome/fontawesome-svg-core";

library
  .add
  //Solid icon
  ();
function Layout({ children }) {
  return (
    <div>
      <ParallaxProvider>
        <Header />

        <main className="">{children}</main>
        <Footer />
      </ParallaxProvider>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
